import React from "react";
import { Route, Redirect } from "react-router-dom";
import bcrypt from "bcryptjs";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const storedHashedPassword = localStorage.getItem("password");
    const password = process.env.REACT_APP_ResidentsPassword || "SLCommunity";
    const isAuthenticated = storedHashedPassword && bcrypt.compareSync(password, storedHashedPassword);

    return <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default ProtectedRoute;
