import React, { useState, useEffect, Image } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";
import summitLogo from "./icons/summit-logo.png";

const MeetingDocuments = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const homeRouterClick = () => {
        history.push("/");
    };

    const galleryRouterClick = () => {
        history.push("/gallery");
    };

    const residentsOnlyRouterClick = () => {
        history.push("/residentsonly");
    };

    const newsNoticesRouterClick = () => {
        history.push("/newsnotices");
    };

    const meetingDocumentsRouterClick = () => {
        history.push("/meetingdocuments");
    };

    const governingDocumentsRouterClick = () => {
        history.push("/governingdocuments");
    };

    const contactRouterClick = () => {
        history.push("/contact");
    };

    return (
        <div>
            <Helmet>
                <title>Meeting Documents | Summit Lofts Condominium Association</title>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div>
                <div className="col-12 lg:col-6 xl:col-3" style={{ width: "100%", cursor: "default", display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <div>
                        <img style={isMobile ? { maxWidth: "250px", marginLeft: "20px" } : {}} src={summitLogo} alt="Summit Lofts Logo"></img>
                    </div>
                </div>
                <hr></hr>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => homeRouterClick()}>
                        HOME
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => galleryRouterClick()}>
                        GALLERY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => residentsOnlyRouterClick()}>
                        RESIDENTS ONLY
                    </a>
                </div>
                <br></br>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => window.open("https://www.paylease.com/registration/pay_portal/24191181/STD?vpw=1728")}>
                        PAY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => newsNoticesRouterClick()}>
                        NEWS & NOTICES
                    </a>
                    <a className="link" style={{ paddingRight: "20px", textDecoration: "underline", color: "black", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => meetingDocumentsRouterClick()}>
                        MEETING DOCS
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => governingDocumentsRouterClick()}>
                        GOVERNING DOCS
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => contactRouterClick()}>
                        CONTACT
                    </a>
                </div>
                <br></br>
            </div>
            <div className="meeting-documents-banner">
                <div className="banner-content">
                    <h1 style={{ fontWeight: "bold" }}>MEETING DOCUMENTS</h1>
                </div>
            </div>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                    style={{
                        flexDirection: "column",
                    }}
                >
                    <br></br>
                    <h4 style={{ textDecoration: "underline" }}>GENERAL MEETING PRESENTATIONS</h4>
                    <br></br>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <h4 style={{ fontWeight: "bold" }}>2024</h4>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/SLCA_August_2024_Updates.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            SLCA August 2024 Updates.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/july_2024_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            July 2024 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/may_2024_general_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            May 2024 General Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/may_2024_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            May 2024 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/march_2024_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            March 2024 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/january_2024_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            January 2024 Meeting.pdf
                        </a>
                    </div>
                    <br></br>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <h4 style={{ fontWeight: "bold" }}>2023</h4>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/november_2023_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            November 2023 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/october_2023_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            October 2023 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/september_2023_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            September 2023 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/july_2023_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            July 2023 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/may_2023_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            May 2023 Meeting.pdf
                        </a>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/general-meeting-documents/march_2023_meeting.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            March 2023 Meeting.pdf
                        </a>
                    </div>

                    <br></br>
                    <h4 style={{ textDecoration: "underline" }}>BOARD MEETING PRESENTATIONS</h4>
                    <br></br>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <h4 style={{ fontWeight: "bold" }}>2024</h4>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="/assets/documents/board-meeting-documents/march_2024_minutes.pdf" target="_blank" download style={{ textDecoration: "underline", color: "black", cursor: "pointer" }}>
                            March 2024 Minutes.pdf
                        </a>
                    </div>
                    <br></br>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(MeetingDocuments, comparisonFn);
