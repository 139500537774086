import React, { useState, useEffect, Image } from "react";
import { useHistory } from "react-router-dom";
import bcrypt from "bcryptjs";
import { Button } from "primereact/button";
import summitLogo from "./icons/summit-logo.png";

const password = process.env.REACT_APP_ResidentsPassword || "SLCommunity";
const salt = bcrypt.genSaltSync(10);
const hash = bcrypt.hashSync(password, salt);

const Login = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [inputPassword, setInputPassword] = useState("");
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();

        if (bcrypt.compareSync(inputPassword, hash)) {
            localStorage.setItem("password", hash);
            history.push("/residentsonly");
        } else {
            alert("Incorrect password");
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const containerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
    };

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    const imageStyle = isMobile ? { width: "70%", marginLeft: "65px" } : {};
    const h1Style = isMobile ? { fontSize: "1.5em", textAlign: "center" } : { textAlign: "center" };

    return (
        <div style={containerStyle}>
            <div>
                <img src={summitLogo} alt="Summit Lofts Logo" style={imageStyle}></img>
                <h1 style={h1Style}>Login to Resident Portal</h1>
                <form onSubmit={handleSubmit} style={formStyle}>
                    <div>
                        <label>Enter Community Password:</label>
                        <input style={{ width: "120px" }} type="password" value={inputPassword} onChange={(e) => setInputPassword(e.target.value)} />
                    </div>
                    <br></br>
                    <Button style={{ minWidth: "350px", marginTop: isMobile ? "20px" : "0px" }} id="logoutButton" type="submit" className="p-button-secondary mr-2 mb-2" label="Login" />{" "}
                </form>
            </div>
        </div>
    );
};

export default Login;
