import React, { useState, useEffect, useRef } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";
import summitLogo from "./icons/summit-logo.png";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isPhone, setIsPhone] = useState(window.innerWidth <= 768);

    const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);

    const [recipientEmails, setRecipientEmails] = useState("");

    const [contactTarget, setContactTarget] = useState("");
    const [message, setMessage] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [file, setFile] = useState(null);

    const handleNameChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^a-zA-Z\s]/g, "");
        setUserName(value);
    };

    const handlePhoneChange = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, "");
        value = value.substring(0, 10);
        let formattedValue = value;

        if (value.length > 6) {
            formattedValue = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6)}`;
        } else if (value.length > 3) {
            formattedValue = `(${value.substring(0, 3)}) ${value.substring(3)}`;
        } else if (value.length > 0) {
            formattedValue = `(${value}`;
        }

        setUserPhone(formattedValue);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

    const isFormValid = contactTarget && contactTarget.trim() !== "" && message && userName && emailRegex.test(userEmail) && phoneRegex.test(userPhone);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsPhone(window.innerWidth <= 600);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (contactTarget === "Summit Lofts Board") {
            setRecipientEmails("b.eikmann@gmail.com,steph_barron@outlook.com,chris.young.sd@gmail.com,smmaddockrn@yahoo.com,jbehrmann@rcirealty.com");
        } else if (contactTarget === "RCI Realty") {
            setRecipientEmails("b.eikmann@gmail.com,steph_barron@outlook.com,chris.young.sd@gmail.com,smmaddockrn@yahoo.com,jbehrmann@rcirealty.com");
        } else {
            setRecipientEmails("");
        }
    }, [contactTarget]);

    const sendEmail = (e) => {
        e.preventDefault();

        e.target.elements.to_email.value = recipientEmails;

        emailjs.sendForm(process.env.REACT_APP_emailJsServiceId || "service_f2abpkm", process.env.REACT_APP_emailJsTemplateId || "template_nfomtte", e.target, process.env.REACT_APP_emailJsKey || "Rc5lLaQmAQ1tMJ1nV").then(
            (result) => {
                console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );

        alert("Your message has been sent. Thank you!");
        setMessage("");
        setUserName("");
        setUserEmail("");
        setUserPhone("");
        setFile("");
        e.target.elements.user_file.value = "";

        recaptchaRef.current.reset();
        setIsCaptchaCompleted(false);
    };

    const recaptchaRef = useRef();

    const homeRouterClick = () => {
        history.push("/");
    };

    const galleryRouterClick = () => {
        history.push("/gallery");
    };

    const residentsOnlyRouterClick = () => {
        history.push("/residentsonly");
    };

    const newsNoticesRouterClick = () => {
        history.push("/newsnotices");
    };

    const meetingDocumentsRouterClick = () => {
        history.push("/meetingdocuments");
    };

    const governingDocumentsRouterClick = () => {
        history.push("/governingdocuments");
    };

    const contactRouterClick = () => {
        history.push("/contact");
    };

    return (
        <div>
            <Helmet>
                <title>Contact Us | Summit Lofts Condominium Association</title>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div>
                <div className="col-12 lg:col-6 xl:col-3" style={{ width: "100%", cursor: "default", display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <div>
                        <img style={isMobile ? { maxWidth: "250px", marginLeft: "20px" } : {}} src={summitLogo} alt="Summit Lofts Logo"></img>
                    </div>
                </div>
                <hr></hr>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => homeRouterClick()}>
                        HOME
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => galleryRouterClick()}>
                        GALLERY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => residentsOnlyRouterClick()}>
                        RESIDENTS ONLY
                    </a>
                </div>
                <br></br>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => window.open("https://www.paylease.com/registration/pay_portal/24191181/STD?vpw=1728")}>
                        PAY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => newsNoticesRouterClick()}>
                        NEWS & NOTICES
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => meetingDocumentsRouterClick()}>
                        MEETING DOCS
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => governingDocumentsRouterClick()}>
                        GOVERNING DOCS
                    </a>
                    <a className="link" style={{ paddingRight: "20px", textDecoration: "underline", color: "black", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => contactRouterClick()}>
                        CONTACT
                    </a>
                </div>
                <br></br>
            </div>
            <div className="contact-banner">
                <div className="banner-content">
                    <h1 style={{ fontWeight: "bold" }}>CONTACT US</h1>
                </div>
            </div>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                    style={{
                        flexDirection: "column",
                        textAlign: "center",
                        marginLeft: isMobile ? "70px" : "0px",
                    }}
                >
                    <h5 style={{ width: isMobile ? "80%" : "100%" }}>
                        For all items requiring action, including maintenance and housekeeping, please contact our management company, Rodemyer Christel, Inc.
                        <br />
                        <b>Call for emergencies or urgent matters only.</b>
                    </h5>
                    <h5 style={{ textDecoration: "underline", width: isMobile ? "80%" : "100%" }}>
                        <b>If there is an after hours emergency, please call 314-726-9877</b>
                    </h5>
                    <h5 style={{ width: isMobile ? "80%" : "100%" }}>Regarding policy matters, they are best communicated to the Board of Managers at the monthly meetings, or by email for issues that cannot wait.</h5>
                    <hr></hr>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: isMobile ? "column" : "row" }}>
                        <div style={{ margin: isMobile ? "10px 0" : "0 50px" }}>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                <b>Rodemyer Christel, Inc.</b>
                            </p>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                3630 Grandel Square
                                <br />
                                St. Louis, MO 63108
                            </p>
                        </div>
                        <div style={{ margin: isMobile ? "10px 0" : "0 50px" }}>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                <u>Mailing Address</u>
                            </p>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                <b>Rodemyer Christel, Inc.</b>
                            </p>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                PO Box 957832
                                <br />
                                ​St. Louis, MO 63195-7832
                            </p>
                        </div>
                        <div style={{ margin: isMobile ? "10px 0" : "0 50px" }}>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                <b>​Jamie Behrmann</b>
                            </p>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                Office: 314-454-0800 x330
                                <br />
                                Fax: 314-454-1556
                            </p>
                            <p style={{ marginRight: isMobile ? "50px" : "0px" }}>
                                Website:{" "}
                                <a href="http://www.rcirealty.com" target="_blank" rel="noopener noreferrer">
                                    http://www.rcirealty.com
                                </a>
                            </p>
                        </div>
                    </div>
                    <hr></hr>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "80%", backgroundColor: "white", borderRadius: "10px", padding: "5px" }}>
                    <h1 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}> Contact Board of Managers</h1>
                    <hr></hr>
                    <form onSubmit={sendEmail} style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", alignItems: "center" }}>
                        <label>Who are you trying to contact? *</label>
                        <select style={{ maxWidth: isPhone ? "70%" : "" }} className="p-inputtext p-component" name="contact_target" value={contactTarget} onChange={(e) => setContactTarget(e.target.value)}>
                            <option value="">Select...</option>
                            <option value="Summit Lofts Board">Summit Lofts Board</option>
                            <option value="RCI Realty">RCI Realty</option>
                        </select>
                        <label>Message: *</label>
                        <textarea className="p-inputtext p-component" name="message" placeholder="Enter message here" style={{ resize: "none", height: "100px", maxWidth: isPhone ? "70%" : "" }} value={message} onChange={(e) => setMessage(e.target.value)} maxLength={1000} />
                        <label>Your Name: *</label>
                        <input style={{ maxWidth: isPhone ? "70%" : "" }} className="p-inputtext p-component" type="text" name="user_name" placeholder="John Appleseed" value={userName} onChange={handleNameChange} /> <label>Your Email: *</label>
                        <input style={{ maxWidth: isPhone ? "70%" : "" }} className="p-inputtext p-component" type="text" name="user_email" placeholder="example@gmail.com" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                        <label>Your Phone: *</label>
                        <input style={{ maxWidth: isPhone ? "70%" : "" }} className="p-inputtext p-component" type="text" name="user_phone" placeholder="(123) 456-7890" value={userPhone} onChange={handlePhoneChange} />
                        <label>Upload File/Photo (500KB Max): </label>
                        <input style={{ maxWidth: isPhone ? "70%" : "" }} className="p-inputtext p-component" type="file" name="user_file" onChange={handleFileChange} />
                        <div
                            style={{
                                transform: isPhone ? "scale(0.7)" : "none",
                                transformOrigin: "center",
                                gridColumn: "span 2",
                                display: "flex",
                                justifyContent: "center",
                                maxWidth: isPhone ? "85%" : "",
                            }}
                        >
                            <ReCAPTCHA ref={recaptchaRef} sitekey="6LfP5_IpAAAAAETjWSpjTESWSyoiFnjVADBRhhih" onChange={() => setIsCaptchaCompleted(true)} />
                        </div>
                        <div style={{ gridColumn: "span 2", display: "flex", justifyContent: isPhone ? "flex-start" : "center" }}>
                            <input type="hidden" name="to_email" />
                            <Button id="submitFormButton" type="submit" className="mr-2 p-button-secondary mb-2" style={{ height: "35px", backgroundColor: "black", color: "white", marginLeft: isPhone ? "33%" : "0" }} disabled={!isFormValid || !isCaptchaCompleted}>
                                Send
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Contact, comparisonFn);
