import React from "react";
import { Route, useLocation, useHistory } from "react-router-dom";

export const AppFooter = (props) => {
    const history = useHistory();

    const homeRouterClick = () => {
        history.push("/");
    };

    const galleryRouterClick = () => {
        history.push("/gallery");
    };
    return (
        <div>
            <hr></hr>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <a className="link" style={{ paddingRight: "20px", textDecoration: "underline", color: "black", cursor: "pointer" }} onClick={() => homeRouterClick()}>
                    HOME
                </a>
                <a className="link" style={{ textDecoration: "underline", color: "black", cursor: "pointer" }} onClick={() => galleryRouterClick()}>
                    GALLERY
                </a>
            </div>
            <hr></hr>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "10px" }} className="font-medium ml-2">
                    © 2024 | Summit Lofts
                </span>
            </div>
        </div>
    );
};
