import React, { useState } from "react";

const Collapsible = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const buttonStyle = {
        backgroundColor: isOpen ? "#a3a3a3" : "#cccccc",
        color: "black",
        cursor: "pointer",
        padding: "18px",
        width: "100%",
        border: "none",
        textAlign: "left",
        outline: "none",
        fontSize: "15px",
    };

    const contentStyle = isOpen ? { padding: "0 18px", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden", backgroundColor: "#dddddd" } : { display: "none" };

    const [firstPart, secondPart] = title.split("|");

    return (
        <div>
            <button style={buttonStyle} onClick={() => setIsOpen(!isOpen)}>
                <strong>{firstPart} | </strong>
                {secondPart}
            </button>
            <div style={contentStyle}>{children}</div>
        </div>
    );
};

export default Collapsible;
