import React, { useState, useEffect, Image } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import summitLogo from "./icons/summit-logo.png";

const Home = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const homeRouterClick = () => {
        history.push("/");
    };

    const galleryRouterClick = () => {
        history.push("/gallery");
    };

    const residentsOnlyRouterClick = () => {
        history.push("/residentsonly");
    };

    return (
        <div>
            <Helmet>
                <title>Home | Summit Lofts Condominium Association</title>
            </Helmet>
            <div>
                <div className="col-12 lg:col-6 xl:col-3" style={{ width: "100%", cursor: "default", display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <div>
                        <img style={isMobile ? { maxWidth: "250px", marginLeft: "20px" } : {}} src={summitLogo} alt="Summit Lofts Logo"></img>
                    </div>
                </div>
                <hr></hr>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", textDecoration: "underline", color: "black", cursor: "pointer" }} onClick={() => homeRouterClick()}>
                        HOME
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => galleryRouterClick()}>
                        GALLERY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => residentsOnlyRouterClick()}>
                        RESIDENTS ONLY
                    </a>
                </div>
                <br></br>
            </div>
            <div className="home-banner">
                <div className="banner-content">
                    <h1 style={{ fontWeight: "bold" }}>PREMIER CONDOMINIUMS</h1>
                    <p style={{ fontWeight: "bold" }}>CREVE COEUR, MISSOURI</p>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: "center", padding: "4rem" }}>
                    <div className="welcome-content" style={{ marginBottom: isMobile ? "2rem" : "0", marginRight: isMobile ? "0" : "10rem", textAlign: isMobile ? "center" : "left" }}>
                        <h2>WELCOME TO</h2>
                        <h1 style={{ whiteSpace: "nowrap" }}>SUMMIT LOFTS</h1>
                        <h5>Luxury condominium residences located at</h5>
                        <h5 style={{ fontWeight: "bold" }}> 630 & 652 Emerson Road.</h5>
                    </div>
                    <div style={{ width: isMobile ? "80%" : "100%", overflow: "hidden", marginLeft: isMobile ? "0" : "2rem" }}>
                        <img src="../assets/layout/images/welcome-pool.png" alt="Welcome Pool" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    </div>
                </div>
            </div>
            <div className="our-location-banner">
                <div style={{ display: "flex", justifyContent: "center", color: "white", textShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)" }}>
                    <div style={{ flexDirection: "column" }}>
                        <div style={{ flexDirection: "row" }}>
                            <br></br>
                            {!isMobile ? <h1 style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>OUR LOCATION</h1> : <h1 style={{ fontWeight: "bold", paddingLeft: "40px" }}>OUR LOCATION</h1>}
                        </div>
                        <div>
                            {isMobile ? (
                                <div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </div>
                            ) : null}
                        </div>
                        <div style={{ flexDirection: "row" }}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3115.1577996527435!2d-90.44895834919247!3d38.668241367924175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df3316c930006f%3A0xa62b14b9eceb3f13!2sSummit%20Lofts%20Condominium!5e0!3m2!1sen!2sus!4v1634608041593!5m2!1sen!2sus"
                                width={isMobile ? "350" : "600"}
                                height={isMobile ? "250" : "450"}
                                style={{ border: "0" }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Home, comparisonFn);
