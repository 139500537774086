import React, { useState, useEffect, Image } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";
import summitLogo from "./icons/summit-logo.png";
import Collapsible from "./Collapsible";

const NewsNotices = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const history = useHistory();

    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.display === "block") {
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        });
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const homeRouterClick = () => {
        history.push("/");
    };

    const galleryRouterClick = () => {
        history.push("/gallery");
    };

    const residentsOnlyRouterClick = () => {
        history.push("/residentsonly");
    };

    const newsNoticesRouterClick = () => {
        history.push("/newsnotices");
    };

    const meetingDocumentsRouterClick = () => {
        history.push("/meetingdocuments");
    };

    const governingDocumentsRouterClick = () => {
        history.push("/governingdocuments");
    };

    const contactRouterClick = () => {
        history.push("/contact");
    };

    return (
        <div>
            <Helmet>
                <title>News & Notices | Summit Lofts Condominium Association</title>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div>
                <div className="col-12 lg:col-6 xl:col-3" style={{ width: "100%", cursor: "default", display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <div>
                        <img style={isMobile ? { maxWidth: "250px", marginLeft: "20px" } : {}} src={summitLogo} alt="Summit Lofts Logo"></img>
                    </div>
                </div>
                <hr></hr>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => homeRouterClick()}>
                        HOME
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => galleryRouterClick()}>
                        GALLERY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => residentsOnlyRouterClick()}>
                        RESIDENTS ONLY
                    </a>
                </div>
                <br></br>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => window.open("https://www.paylease.com/registration/pay_portal/24191181/STD?vpw=1728")}>
                        PAY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", textDecoration: "underline", color: "black", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => newsNoticesRouterClick()}>
                        NEWS & NOTICES
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => meetingDocumentsRouterClick()}>
                        MEETING DOCS
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => governingDocumentsRouterClick()}>
                        GOVERNING DOCS
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer", fontSize: isMobile ? "8px" : "inherit", whiteSpace: "nowrap" }} onClick={() => contactRouterClick()}>
                        CONTACT
                    </a>
                </div>
                <br></br>
            </div>
            <div className="news-notices-banner">
                <div className="banner-content">
                    <h1 style={{ fontWeight: "bold" }}>NEWS & NOTICES</h1>
                </div>
            </div>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                    <div style={{ flexBasis: "100%", marginRight: "20px", padding: "20px" }}>
                    <Collapsible title="Pool Closure | Posted: September 9, 2024">
                            <div className="collapsible-content">
                                <p>The pool is scheduled to be closed for the season effective September 30th.</p>
                            </div>
                        </Collapsible>
                        <br></br>
                        <Collapsible title="630 Roofing Update | Posted: August 8, 2024">
                            <div className="collapsible-content">
                                <p>The 630 building roof recoat is 100% complete. A final contractor punch list and walkthrough will be accomplished in late August.</p>
                            </div>
                        </Collapsible>
                        <br></br>
                        <Collapsible title="Notice: Parking Lot Repair | Posted: August 8, 2024">
                            <div className="collapsible-content">
                                <p>The previous contactor has been dismissed due to poor performance. A new contract has been awarded to St Louis Paving Company for crack filling, sealing and restriping the entire parking lot. Scheduling has been requested for late August. The process should take approximately 3 days and access to one garage door and one side of the driveway will be maintained at all times. Notices will be posted when the schedule is confirmed.</p>
                            </div>
                        </Collapsible>
                        <br></br>
                        <Collapsible title="Unit 204 - For Sale Mid-July | Posted: June 25, 2024">
                            <div className="collapsible-content">
                                <p>Resident Bruce Sokolik (Unit 204, 630 Building) is going to have his late mother's unit for sale mid-July and is asking $425,000. If you have any interest in learning more, please contact Bruce directly at (314) 420-2800. Thanks, Summit Lofts BOD</p>
                            </div>
                        </Collapsible>
                        <br></br>
                        <Collapsible title="Summer Water Memo | Posted: May 30, 2024">
                            <div className="collapsible-content">
                                <a style={{ textDecoration: "underline" }} href="/assets/documents/news-notices-documents/summer_water_memo.pdf" target="_blank" rel="noreferrer" rel="noopener">
                                    Download
                                </a>
                            </div>
                        </Collapsible>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h5 style={{ fontWeight: "bold" }}>General Meetings will be held on:</h5>
                <h5 style={{ textAlign: "center" }}>
                    September 24, 2024
                    <br />
                    ​November 25, 2024 <br />
                    <b> at 6:00pm in the clubhouse</b>
                </h5>
            </div>
            <hr></hr>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h5 style={{ fontWeight: "bold", textDecoration: "underline", textAlign: "center" }}>Association Meetings are held in the clubhouse on the 4th Monday of each month.​</h5>
                <h5 style={{ fontWeight: "bold", textAlign: "center" }}>​Board Meetings are held monthly. 4th Monday on each even month after general meetings on odd months.​</h5>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(NewsNotices, comparisonFn);
