import React, { useState, useEffect, Image } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "primereact/button";
import summitLogo from "./icons/summit-logo.png";

const ResidentsOnly = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const homeRouterClick = () => {
        history.push("/");
    };

    const galleryRouterClick = () => {
        history.push("/gallery");
    };

    const residentsOnlyRouterClick = () => {
        history.push("/residentsonly");
    };

    const newsNoticesRouterClick = () => {
        history.push("/newsnotices");
    };

    const meetingDocumentsRouterClick = () => {
        history.push("/meetingdocuments");
    };

    const governingDocumentsRouterClick = () => {
        history.push("/governingdocuments");
    };

    const contactRouterClick = () => {
        history.push("/contact");
    };

    const logout = () => {
        localStorage.removeItem("password");
        history.push("/");
    };

    return (
        <div>
            <Helmet>
                <title>Residents Only | Summit Lofts Condominium Association</title>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div>
                <div className="col-12 lg:col-6 xl:col-3" style={{ width: "100%", cursor: "default", display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <div>
                        <img style={isMobile ? { maxWidth: "250px", marginLeft: "20px" } : {}} src={summitLogo} alt="Summit Lofts Logo"></img>
                    </div>
                </div>
                <hr></hr>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => homeRouterClick()}>
                        HOME
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => galleryRouterClick()}>
                        GALLERY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", textDecoration: "underline", color: "black", cursor: "pointer" }} onClick={() => residentsOnlyRouterClick()}>
                        RESIDENTS ONLY
                    </a>
                </div>
                <br></br>
            </div>
            <div className="residents-only-banner">
                <div className="banner-content">
                    <h1 style={{ fontWeight: "bold" }}>RESIDENTS ONLY</h1>
                </div>
            </div>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                    style={{
                        flexDirection: "column",
                    }}
                >
                    <div style={{ flexDirection: "row" }}>
                        <Button style={{ minWidth: "350px" }} id="payRodemeyerChristelButton" type="button" className="p-button-secondary mr-2 mb-2" label="Pay Rodemeyer Christel" onClick={() => window.open("https://www.paylease.com/registration/pay_portal/24191181/STD?vpw=1728")} />
                    </div>
                    <div style={{ flexDirection: "row" }}>
                        <Button style={{ minWidth: "350px" }} id="newsAndNoticesButton" type="button" className="p-button-secondary mr-2 mb-2" label="News & Notices" onClick={() => newsNoticesRouterClick()} />
                    </div>
                    <div style={{ flexDirection: "row" }}>
                        <Button style={{ minWidth: "350px" }} id="meetingDocumentsButton" type="button" className="p-button-secondary mr-2 mb-2" label="Meeting Documents" onClick={() => meetingDocumentsRouterClick()} />
                    </div>
                    <div style={{ flexDirection: "row" }}>
                        <Button style={{ minWidth: "350px" }} id="governingDocumentsButton" type="button" className="p-button-secondary mr-2 mb-2" label="Governing Documents" onClick={() => governingDocumentsRouterClick()} />
                    </div>
                    <div style={{ flexDirection: "row" }}>
                        <Button style={{ minWidth: "350px" }} id="contactButton" type="button" className="p-button-secondary mr-2 mb-2" label="Contact" onClick={() => contactRouterClick()} />
                    </div>
                    <br></br>
                    <div style={{ flexDirection: "row" }}>
                        <Button style={{ minWidth: "350px" }} id="logoutButton" type="button" className="p-button-danger mr-2 mb-2" label="Logout" onClick={() => logout()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(ResidentsOnly, comparisonFn);
