import React, { useState, useEffect, Image } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import summitLogo from "./icons/summit-logo.png";

const Gallery = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [galleryIsMobile, setGalleryIsMobile] = useState(window.innerWidth <= 1050);

    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setGalleryIsMobile(window.innerWidth <= 1050);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const homeRouterClick = () => {
        history.push("/");
    };

    const galleryRouterClick = () => {
        history.push("/gallery");
    };

    const residentsOnlyRouterClick = () => {
        history.push("/residentsonly");
    };

    const openCarousel = (index) => {
        setSelectedImageIndex(index);
    };

    const closeCarousel = () => {
        setSelectedImageIndex(null);
    };

    const images = [
        "/assets/layout/images/carouselImages/1.png",
        "/assets/layout/images/carouselImages/2.png",
        "/assets/layout/images/carouselImages/3.png",
        "/assets/layout/images/carouselImages/4.png",
        "/assets/layout/images/carouselImages/5.png",
        "/assets/layout/images/carouselImages/6.png",
        "/assets/layout/images/carouselImages/7.png",
        "/assets/layout/images/carouselImages/8.png",
        "/assets/layout/images/carouselImages/9.png",
        "/assets/layout/images/carouselImages/10.png",
        "/assets/layout/images/carouselImages/11.png",
        "/assets/layout/images/carouselImages/12.png",
    ];

    return (
        <div>
            <Helmet>
                <title>Gallery | Summit Lofts Condominium Association</title>
            </Helmet>
            <div>
                <div className="col-12 lg:col-6 xl:col-3" style={{ width: "100%", cursor: "default", display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <div>
                        <img style={isMobile ? { maxWidth: "250px", marginLeft: "20px" } : {}} src={summitLogo} alt="Summit Lofts Logo"></img>
                    </div>
                </div>
                <hr></hr>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => homeRouterClick()}>
                        HOME
                    </a>
                    <a className="link" style={{ paddingRight: "20px", color: "black", textDecoration: "underline", cursor: "pointer" }} onClick={() => galleryRouterClick()}>
                        GALLERY
                    </a>
                    <a className="link" style={{ paddingRight: "20px", cursor: "pointer" }} onClick={() => residentsOnlyRouterClick()}>
                        RESIDENTS ONLY
                    </a>
                </div>
                <br></br>
            </div>
            <div className="gallery-banner">
                <div className="banner-content">
                    <h1 style={{ fontWeight: "bold" }}>GALLERY</h1>
                </div>
            </div>
            <br></br>
            <div style={isMobile ? { display: "flex", justifyContent: "center", marginLeft: "10%" } : {}}>
                <div style={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "repeat(auto-fit, minmax(400px, 1fr))", width: isMobile ? "100%" : "auto", paddingLeft: isMobile ? "0px" : "20px" }}>
                    {images.map((image, index) => (
                        <div key={index}>
                            <img
                                src={image}
                                alt={`Summit Lofts Gallery ${index + 1}`}
                                style={{
                                    width: isMobile ? "90%" : "auto",
                                    cursor: "pointer",
                                }}
                                onClick={() => openCarousel(index)}
                            />{" "}
                        </div>
                    ))}
                </div>
            </div>

            {selectedImageIndex !== null && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1000",
                    }}
                    onClick={closeCarousel}
                >
                    <div
                        style={{
                            backgroundColor: "#fff",
                            padding: "20px",
                            paddingTop: "40px",
                            borderRadius: "5px",
                            position: "relative",
                            width: "80%",
                            height: isMobile ? "60%" : "80%",
                            overflow: "auto",
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <span
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                cursor: "pointer",
                                fontSize: "24px",
                                color: "#888",
                            }}
                            onClick={closeCarousel}
                        >
                            &times;
                        </span>
                        <div>
                            <img
                                src={images[selectedImageIndex]}
                                alt={`Summit Lofts Gallery ${selectedImageIndex + 1}`}
                                style={{
                                    maxWidth: galleryIsMobile ? "100%" : "800px",
                                    maxHeight: galleryIsMobile ? "auto" : "800px",
                                    margin: "0 auto",
                                    display: "block",
                                    padding: "0",
                                    objectFit: "contain",
                                }}
                            />{" "}
                            {!isMobile ? (
                                <div style={{ display: "flex", justifyContent: "center", overflowX: "scroll", marginTop: "30px" }}>
                                    {images.map((image, index) => (
                                        <img key={index} src={image} alt={`Thumbnail ${index + 1}`} style={{ width: "90px", cursor: "pointer" }} onClick={() => setSelectedImageIndex(index)} />
                                    ))}
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center", overflowX: "scroll", marginTop: "60px" }}>
                                    {images.map((image, index) => (
                                        <img key={index} src={image} alt={`Thumbnail ${index + 1}`} style={{ width: "90px", cursor: "pointer" }} onClick={() => setSelectedImageIndex(index)} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Gallery, comparisonFn);
