import React, { useState, useEffect, useRef } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { AppFooter } from './AppFooter';
import { AppConfig } from './AppConfig';

import Home from './components/Home';
import Gallery from './components/Gallery';

import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import ResidentsOnly from './components/ResidentsOnly';
import NewsNotices from './components/NewsNotices';
import MeetingDocuments from './components/MeetingDocuments';
import GoverningDocuments from './components/GoverningDocuments';
import Contact from './components/Contact';


import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';

const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    return (
        <div>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-main-container">
                <div className="layout-main">
                    <Route path="/" exact render={() => <Home colorMode={layoutColorMode} location={location} />} />
                    <Route path="/login" component={Login} />
                    <Route path="/gallery" component={Gallery} />
                    <ProtectedRoute path="/residentsonly" component={ResidentsOnly} />
                    <ProtectedRoute path="/newsnotices" component={NewsNotices} />
                    <ProtectedRoute path="/meetingdocuments" component={MeetingDocuments} />
                    <ProtectedRoute path="/governingdocuments" component={GoverningDocuments} />
                    <ProtectedRoute path="/contact" component={Contact} />
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

        </div>
    );

}

export default App;
